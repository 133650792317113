<template>
  <div>404 page</div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped></style>
